.contactpage {
  padding: 10rem 0;
  background-color: #f5f5f5;
  color: $color-grey-dark-2;

  &__content {
    @include gridblock(2rem, initial);
    font-size: $default-font-size;
  }

  &__heading {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    letter-spacing: 1.6px;
    color: $color-grey-dark-2;
    margin-bottom: 6rem;
    text-align: center;

    &::after {
      content: "";
      display: block;
      border-bottom: 4px solid $color-blue;
      margin: 0 auto;
      width: 15%;
      height: 1px;
      //   margin-bottom: 7rem;
    }

    @include respond(phone) {
      font-size: 2rem;
      margin-bottom: 2rem;
    }
  }

  &__subheading {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    color: $color-grey-dark-2;
    margin-bottom: 1.2rem;
  }

  &__formbox {
  }

  &__text {
    justify-self: center;
    font-family: "Inter", sans-serif;

    &--info {
      margin-top: 3rem;
      margin-bottom: 2.4rem;
    }

    @include respond(phone) {
      margin-top: 7rem;
    }
  }
}
