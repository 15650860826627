.productspage {
  font-size: $default-font-size;
  padding: 5rem 0;

  &--title {
    font-weight: 900;
    letter-spacing: 1.6px;
    color: $color-grey-dark-3;
    margin-bottom: 2rem;
    text-align: center;

    &::after {
      content: "";
      display: block;
      border-bottom: 4px solid $color-blue;
      margin: 0 auto;
      width: 5%;
      height: 1px;
      margin-bottom: 7rem;
    }
  }

  &__search {
    &__grid {
      @include gridblock(2rem, center);
    }

    &--item {
      &-input {
        width: 60%;
        height: 4rem;
        border: 1px solid #ccc;
        border-radius: 0.5rem;
        padding: 0 1rem;
        font-size: 1.6rem;
        font-weight: 400;
        text-align: center;
        color: $color-grey-dark-3;
        background-color: $color-white;
        font-family: "Poppins", sans-serif;
        transition: all 0.3s ease-in-out;

        &:focus {
          outline: none;
          border: 2px solid #2684ff;
        }

        @include respond(tab-port) {
          width: 100%;
          text-align: left;
        }
      }
    }
  }

  &__products {
    padding: 7rem 0;
    // margin: auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 5rem 2rem;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    font-size: $default-font-size;

    &--item {
      min-height: 50rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 0 2rem;
      box-shadow: 0 1px 3px -2px rgba(0, 0, 0, 0.18),
        0 1px 2px rgba(0, 0, 0, 0.15);
      transition: all 0.3s linear;

      &:hover {
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
      }

      &-img {
        width: 100%;
        max-height: 100%;
        // object-fit: cover;
      }

      &-info {
        padding: 2rem 0;
        text-align: center;

        &-category {
          color: #555;
        }

        &-btn {
          margin-top: 4rem;
          margin-bottom: 2rem;
          background-color: #ffd180;
          padding: 1.2rem 4rem;
          border: none;
          border-radius: 10px;
          font-family: "Poppins", sans-serif;
          font-weight: 700;
          letter-spacing: 1px;
          font-size: 1.5rem;
          text-transform: uppercase;
          cursor: pointer;
          color: $color-black;
          transition: all 0.2s linear;

          &:hover {
            color: $color-white;
          }
        }
      }
    }
  }
}

// single product item page
.productitem {
  padding: 7rem 0 12rem;
  @include gridblock(2rem, initial);
  font-size: $default-font-size;
  font-family: "Inter", sans-serif;

  &__image {
    background-repeat: no-repeat;

    &--img {
      display: block;
      width: 90%;
      max-height: 100%;
      // pointer-events: none;

      // &:hover {
      //   opacity: 0;
      // }
    }
    &--caption {
      margin-top: 1rem;
      color: #444;
      width: 80%;
      text-align: center;
    }
  }

  &__info {
    padding: 2rem 0;
    // line-height: 1.7;

    @include respond(phone) {
      padding: 6rem 0;
    }

    &--title {
      font-weight: 400;
      font-family: "Poppins", sans-serif;
      margin-bottom: 1rem;
    }

    &--description {
      margin-top: 2rem;
    }

    &--form {
      margin-top: 4rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      @include respond(phone) {
        margin-top: 0;
      }

      &-title {
        font-weight: 400;
        font-family: "Poppins", sans-serif;
        color: $color-brown-dark;
        margin-bottom: 3rem;
      }

      & input,
      & textarea {
        border: 1px solid #ccc;
      }
    }
  }
}
