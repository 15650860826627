.center-content {
  max-width: 120rem !important;
  margin: 0 auto !important;

  @include respond(tab-land) {
    margin: 0 4rem !important;
  }
}

.full-height {
  height: 100vh !important;
}

.bg-brown {
  background-color: $color-brown !important;
}

.bg-blue-light {
  background-color: $color-blue-light !important;
}

.bg-white {
  background-color: $color-white !important;
}

.bg-grey-light {
  background-color: $color-grey-light-1 !important;
}

.sub-title {
  font-family: "Poppins", sans-serif;
  color: $color-grey-dark;
  font-size: 1.3rem;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 5px;

  @include respond(phone) {
    display: none;
  }
}

.title__section {
  font-family: "Poppins", sans-serif;
  color: $color-blue-dark;
  font-size: 4rem;
  text-align: center;
  font-weight: 200;
  // letter-spacing: 2px;

  &::after {
    content: "";
    display: block;
    border-bottom: 3px solid $color-blue-dark;
    width: 60%;
    height: 1px;
    margin: 0px auto;
    margin-bottom: 7rem;
  }

  &--line {
    border-bottom: 3px solid $color-blue-dark;
    width: 60%;
    height: 1px;
    margin: 0px auto;
    margin-bottom: 7rem;
  }
}

.dotted {
  position: absolute;
  right: 0px;
  top: 20px;
  pointer-events: none;
  user-select: none;

  &--left {
    left: 0px;
    top: -40px;
    opacity: 0.2;
    filter: invert(90%) sepia(55%) saturate(7417%) hue-rotate(222deg)
      brightness(110%) contrast(81%);
  }

  @include respond(tab-land) {
    display: none;
  }
}

.scroll-top {
  position: absolute;
  bottom: 4rem;
  right: 4rem;
  position: fixed;

  &--icon {
    color: $color-grey-dark-3;
    height: 5rem;
    width: 5rem;
    background: $color-white;
    border-radius: 50%;
    padding: 1rem;
    border: 0.1px solid $color-grey-light-3;
  }
}

mark {
  display: inline-block;
  line-height: 0em;
  padding-bottom: 0.5em;
}

.highlight {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 75%,
    rgba($color-blue-dark, 1) 25%
  );

  &--white {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 70%,
      rgba($color-primary, 1) 30%
    );
  }
}

.blue-color {
  color: $color-blue-dark !important;
}

.black {
  color: $color-black !important;
}

.gradient-text {
  background: linear-gradient(to right, $color-blue, $color-primary 60%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.u-center-text {
  text-align: center !important;
}

.u-margin-bottom-small {
  margin-bottom: 1.5rem !important;
}
.u-margin-bottom-medium {
  margin-bottom: 4rem !important;

  @include respond(tab-port) {
    margin-bottom: 3rem !important;
  }
}
.u-margin-bottom-big {
  margin-bottom: 8rem !important;

  @include respond(tab-port) {
    margin-bottom: 5rem !important;
  }
}

.u-margin-top-medium {
  margin-top: 5rem !important;
}

.u-margin-top-big {
  margin-top: 8rem !important;
}
.u-margin-top-huge {
  margin-top: 10rem !important;
}

.u-padding-top-medium {
  padding-top: 6rem !important;
}

.u-padding-bottom-medium {
  padding-bottom: 6rem !important;
}
