.qualitypage {
  font-size: 1.7rem;
  font-family: "Inter", sans-serif;
  line-height: 1.7;
  background-color: #f5f5f5;
  padding: 7rem 0;

  @include respond(phone) {
    text-align: left;
  }

  &__content {
    @include gridblock(2rem, initial);
    text-align: justify;
    margin-bottom: 4rem;

    &--image {
      width: 80%;
      max-height: 100%;
      justify-self: flex-end;

      @include respond(phone) {
        justify-self: initial;
        width: 100%;
      }
    }
  }

  &__card {
    @include gridblock(0, center);

    width: 70%;

    @include respond(phone) {
      width: 90%;
    }

    &__section {
      display: flex;
      flex-direction: column;
      align-items: center;

      @include respond(phone) {
        margin: 5rem 0;
      }
    }

    &--item {
      background-color: white;
      padding: 8rem;
      min-height: 55rem;

      @include respond(phone) {
        padding: 5rem;
      }

      &-icon-2 {
        color: white;
      }

      &-2 {
        background-color: $color-red;
        color: white;
      }
    }
  }
}
