.title__banner {
  padding: 7rem 0;

  &--about {
    background-color: $banner-page-color-1;
  }

  &--products {
    background-color: $banner-page-color-6;
  }

  &--infrastructure {
    background-color: $banner-page-color-2;
  }

  &--team {
    background-color: $banner-page-color-3;
  }

  &--contact {
    background-color: $banner-page-color-4;
  }

  &--quality {
    background-color: $banner-page-color-5;
  }

  &--text {
    font-family: "Inter", sans-serif;
    font-size: 3rem;
    font-weight: 900;
    letter-spacing: 6px;
    color: $color-blue-dark;
    text-transform: uppercase;
    text-align: center;

    @include respond(phone) {
      font-size: 2rem;
    }
  }
}

.footersection {
  background: #000;
  padding: 5rem 0;
  font-family: "Inter", sans-serif;
  text-align: center;
  font-size: 1.9rem;

  & .row {
    width: 100%;
    margin: 1% 0%;
    padding: 0.6% 0%;
    color: gray;
    font-size: 0.8em;

    & a {
      text-decoration: none;
      color: gray;
      transition: 0.5s;

      &:hover {
        color: #fff;
      }

      & i {
        font-size: 2em;
        margin: 0% 1%;
      }
    }

    & ul {
      width: 100%;

      & li {
        display: inline-block;
        margin: 0px 30px;
      }
    }
  }
}

@media (max-width: 720px) {
  .footersection {
    text-align: center;
    padding: 5%;
  }
  .footersection .row ul li {
    display: block;
    margin: 10px 0px;
    text-align: center;
  }
  .footersection .row a i {
    margin: 0% 3%;
  }
}
