.aboutpage {
  margin: 4rem auto 0;
  color: #222;
  font-family: "Inter", sans-serif;

  &__text {
    line-height: 1.7;
    text-align: justify;
    font-size: 1.7rem;

    @include respond(phone) {
      text-align: left;
    }

    &--p {
      margin-bottom: 3rem;
    }

    &--h2 {
      text-transform: uppercase;
      margin: 3rem 0 1rem 0;
    }

    &--domestic {
      @include gridblock(2rem, center);

      &-img {
        width: 60%;
        max-height: 100%;
        justify-self: flex-end;

        @include respond(phone) {
          justify-self: initial;
          width: 100%;
        }
      }
    }
  }

  &__list {
    padding: 3rem;
    // padding: 3rem 1rem;
    // list-style: inside;

    &--sub {
      padding-left: 2rem;
    }
  }

  &__weassure {
    padding: 4rem 5rem 2rem;
    background-color: #f5f5f5;
    font-size: 1.7rem;

    &--h1 {
      text-transform: uppercase;
      margin: 3rem 0 1rem 0;
      text-align: center;
      letter-spacing: 2px;
    }

    &--cards {
      padding: 5rem 0;
      margin: auto;
      @include gridblock(2rem, center);

      &-item {
        min-height: 30rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 5rem;
        padding-bottom: 6rem;
        text-align: center;
        background-color: #fafafa;

        &-icon {
          color: #818a91;
          margin-bottom: 3rem;
        }

        &-title {
          margin-bottom: 3rem;
          color: #333;
        }

        &-text {
          color: #444;
        }
      }
    }
  }
}
