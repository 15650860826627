.teams {
  // padding: 8rem 0;
  font-size: 1.7rem;
  background-color: #f5f5f5;

  &__heading {
    padding: 8rem 0;

    &--title {
      font-weight: 900;
      letter-spacing: 1.6px;
      color: $color-grey-dark-3;
      margin-bottom: 2rem;
      text-align: center;

      &::after {
        content: "";
        display: block;
        border-bottom: 4px solid $color-blue;
        margin: 0 auto;
        width: 15%;
        height: 1px;
        margin-bottom: 7rem;
      }
    }

    &--subtitle {
      font-family: "Inter", sans-serif;
      color: $color-grey-dark-2;
      line-height: 1.5;

      &-join {
        text-align: center;
      }
    }
  }

  &__images {
    @include gridblock(2rem, center);
    padding: 4rem 0;

    &--item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &-name {
        font-size: 2.3rem;
        font-weight: 800;
        margin-bottom: 1rem;
      }

      &-designation {
        font-size: 1.7rem;
        color: $color-grey-dark-2;
        font-weight: 400;
      }

      &-img {
        width: 80%;
        max-height: 100%;
        margin-bottom: 3rem;
      }
    }
  }

  // join team form
  .join-team {
    &__form {
      padding: 8rem;
      display: flex;
      justify-content: center;

      @include respond(phone) {
        width: 100%;
        padding: 0;
      }
    }
  }
}
