.infrapage {
  font-family: "Inter", sans-serif;
  &__content {
    padding: 3rem 0;

    &--item {
      @include gridblock(2rem, initial);
      padding: 7rem 0;

      &-text {
        font-size: 1.7rem;
        line-height: 1.7;
        text-align: justify;

        &-list {
          list-style: inside;
        }
      }

      &-image {
        position: relative;
        &-img {
          width: 100%;
          padding: 0 5rem;
          max-height: 100%;
        }

        &-caption {
          font-size: 1.8rem;
          margin-top: 1rem;
          font-weight: 700;
          text-align: center;
        }
      }
    }
  }
}
