// color
$color-brown: #f3d9c8;
$color-brown-dark: #e5a46c;
$color-brown-light: #fff8f4;

$color-primary: #444cf7;
$color-primary-light: #787eff;
$color-primary-dark: #2932f8;
$color-primary-darker: #24285b;

$color-purple: #654fef;
$color-blue: #4467ea;
$color-blue-dark: #2e469e;
$color-blue-light: #f5f9fd;

$color-red: #da1c4b;

$color-lime-green: #00ff7f;
$color-green: #50c878;
$color-green-dark: #009e60;

$color-orange: #f5a623;

$banner-page-color-1: #ffe6c7;
$banner-page-color-2: #d1f0de;
$banner-page-color-3: #fab6bd;
$banner-page-color-4: #f3d9c8;
$banner-page-color-5: #c8d1f4;
// $banner-page-color-6: #d6efc7;
$banner-page-color-6: lighten(#ffa200, 25%);

$color-grey-light-1: #f7f7f7;
$color-grey-light-2: #eee;
$color-grey-light-3: #d1d1d1;

$color-grey-dark: #9c9c9c;
$color-grey-dark-1: #777;
$color-grey-dark-2: #474747;
$color-grey-dark-3: #333;
$color-grey-dark-4: #242424;

$color-white: #fff;
$color-black: #000;

$white-light-tint: rgba(255, 255, 255, 0.3);
$white-thick-tint: rgba(255, 255, 255, 0.9);
$white: #fff;
$bluish-white: #bcdbee;
$black-light-tint: rgba(0, 0, 0, 0.302);
$black-thick-tint: rgba(0, 0, 0, 0.871);
$black: #000;
$light-pink: #bfdbb4;
$light-grey: #fcfcfc;
$medium-dark-grey: #444;
$dark-grey: #222;
$grey: grey;
$light-blue: #77cae5;
$steel-blue: #4e89ae;
$royal-blue: #4285f4;
$navy-blue: #1963db;
$dark-blue: #3d1f90;
$prussian-blue: #003153;
$light-purple: #376f92;
$purple: #103f5f;
$red: red;
$gold: gold;
$green: green;
$light-green-tint: rgba(245, 160, 30, 0.7);

// FONT
$default-font-size: 1.6rem;

// GRID
$grid-width: 114rem;
$gutter-vertical: 8rem;
$gutter-vertical-small: 6rem;
$gutter-horizontal: 6rem;
