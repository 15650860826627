.button {
  &--primary {
    background-color: $color-brown-dark;
    padding: 1.2rem 8.5rem;
    color: $color-black;
    border: none;
    border-radius: 10px;
    font-family: "Poppins", sans-serif;
    cursor: pointer;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 1.5rem;
    box-shadow: 5px 5px 20px rgba($color-black, 0.2);

    &:hover {
      box-shadow: 5px 5px 10px rgba($color-black, 0.2);
    }

    &-blue {
      background-color: $color-blue;
      color: white;
    }

    &-black {
      background-color: $color-black;
      color: white;
    }

    &--small {
      @include respond(tab-port) {
        margin-top: 7rem;
        font-size: 1.6rem;
      }
      @include respond(phone) {
        margin-top: 10rem;
      }
    }
  }

  &--secondary {
    padding: 0.4rem 3rem;
    color: $color-white;
    background-color: $color-black;
    border-radius: 100px;
    border: 1px solid $color-grey-dark-2;
    cursor: pointer;
    font-weight: bold;
    font-family: "Poppins", sans-serif;
    text-decoration: none;

    &--nav {
      display: flex;
      align-items: center;
      justify-content: space-between;

      & span {
        margin-left: 5px;
      }
    }

    &:hover {
      box-shadow: 4px 4px 4px rgba($color-black, 0.16);
    }
  }
}
