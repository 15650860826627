.home-footer {
  background-color: #222;
  padding: 10rem 0;
  @include gridblock(2rem, center);

  &--left {
    justify-self: center;
    font-size: 1.5rem;
    color: #ccc;

    &-item {
      padding: 4rem;

      &-h4 {
        margin-bottom: 2.4rem;
        font-size: 2rem;

        &::after {
          content: "";
          display: inline-block;
          width: 70%;
          height: 100%;
          margin-right: -100%;
          border-bottom: 1px solid #555;
        }
      }
    }

    &-content {
      padding: 1rem 0;

      &-icon {
        margin-right: 2rem;
      }

      &-text {
        color: #ccc;
        text-decoration: none;

        &:hover {
          color: #fff;
        }
      }
    }
  }

  &--right {
    width: 80%;
    justify-self: center;

    // @include respond(phone) {
    //   width: 100%;
    //   justify-self: center;
    // }
  }

  &__form {
    display: flex;
    flex-direction: column;
    @include respond(tab-port) {
      display: flex;
      flex-direction: column;
      width: 80%;
      margin: auto;
    }

    @include respond(phone) {
      width: 100%;
      padding: 0;
    }
  }
}
