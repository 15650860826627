.container {
  // padding-bottom: 3rem;
  &--bg {
    // background-color: $color-brown;
    object-fit: contain;
  }
}

// Hero Section
.hero__container {
  // height: calc(100vh - 10vh);
}
.hero {
  height: 78vh;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__slider {
    width: 40% !important;

    @include respond(tab-land) {
      display: none;
    }
  }

  &__main {
    width: 50%;

    @include respond(tab-land) {
      margin: auto;
      margin-left: 10rem;
    }
    @include respond(tab-port) {
      margin: auto;
      width: 70%;
    }
    @include respond(phone) {
      margin: auto;
      width: 80%;
    }

    &--head-1 {
      color: $color-grey-dark-1;
      text-transform: uppercase;
      letter-spacing: 5px;
      font-size: 1.5rem;
      font-weight: bolder;

      @include respond(tab-port) {
        font-size: 2rem;
      }
      @include respond(phone) {
        font-size: 1.5rem;
      }
    }

    &--head-2 {
      color: $color-blue-dark;
      font-size: 4rem;
      font-weight: 800;
      margin-bottom: 1.5rem;

      @include respond(tab-port) {
        font-size: 5rem;
      }
      @include respond(phone) {
        font-size: 4rem;
      }
    }

    &--head-3 {
      color: $color-black;
      font-size: 3rem;
      font-weight: 600;
      margin-bottom: 1rem;

      @include respond(tab-port) {
        font-size: 4rem;
      }
      @include respond(phone) {
        font-size: 3rem;
      }
    }

    &--text {
      width: 85%;
      color: $color-grey-dark-2;
      font-size: 1.75rem;
      font-weight: 400;
      margin-bottom: 1.5rem;

      @include respond(tab-port) {
        font-size: 2rem;
      }
      @include respond(phone) {
        font-size: 1.5rem;
      }
    }
  }

  &__image {
    height: 80%;
    justify-self: flex-end;
    @include respond(tab-land) {
      transform: scale(0.7);
    }
    @include respond(tab-port) {
      display: none;
    }
    @include respond(phone) {
      display: none;
    }
  }
}

// Banner
.industry__banner {
  &__images {
    background-color: #fbfbfb !important;
    display: flex;
    justify-content: center;
    pointer-events: none;
    user-select: none;
    &--logo {
      width: 80%;
    }

    @include respond(tab-land) {
      height: 12rem;
    }
  }
}

// ====================================================
// About Section

.about {
  font-family: "Inter", sans-serif;
  // background: $color-blue-light;
  min-height: 100vh;
  padding: 10rem 0 5rem 0;
  display: flex;
  align-items: center;
  flex-direction: column;

  &__text {
    font-size: 2rem;
    line-height: 2;
    color: $color-grey-dark-3;
    padding: 0 1rem 3rem 1rem;
    text-align: justify;
  }

  &__cards {
    max-width: 100% !important;
    padding: 3rem 0;
    margin: auto;
    @include gridblock(2rem, center);
    font-size: $default-font-size;

    &--item {
      background-color: $color-white;
      // width: 30rem;
      min-height: 35rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 5rem;
      position: relative;
      text-align: center;
      box-shadow: 0 0 20px rgba($color-grey-dark, 0.15);
      transition: all 0.2s ease-in-out;

      &:hover {
        transform: scale(1.01);
      }

      &-img {
        width: 40%;
        padding-bottom: 3rem;
        pointer-events: none;
        user-select: none;
      }

      &-h3 {
        padding-bottom: 2rem;
      }

      &-p {
        color: $color-grey-dark-2;
      }
    }

    @include respond(tab-land) {
      padding: 10rem 0;
    }
  }
}

// our products
.products {
  font-family: "Inter", sans-serif;
  min-height: 100vh;
  padding: 10rem 0 1rem 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;

  &__main {
    padding: 3rem 0;
    &--item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10rem;

      @include respond(tab-port) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      &-textbox {
        font-size: $default-font-size;
        // padding-top: 2rem;
        max-width: 60%;
        width: 50%;

        @include respond(tab-port) {
          max-width: 100%;
          width: 100%;
          margin-bottom: 3rem;
        }

        &-head {
          font-size: 2.8rem;
          font-weight: 300;
          // margin-bottom: 3rem;

          & span {
            color: $color-blue;
            font-weight: 600;
          }

          &::after {
            content: "";
            display: block;
            border-bottom: 2px solid $color-blue;
            width: 10%;
            height: 1px;
            margin-bottom: 4rem;
          }
        }

        &-text {
          color: $color-grey-dark-3;
          font-size: 1.7rem;
          line-height: 1.65;
          margin-bottom: 3rem;
        }

        &-link,
        &-link:active,
        &-link:visited {
          text-decoration: none;
          color: $color-blue;
          font-weight: 600;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      &-img {
        width: 40%;
        max-width: 100%;
        height: auto;
        transition: all 0.2s ease-in-out;
        &:hover {
          box-shadow: 0 4px 8px 0 rgba($color-black, 0.14),
            0 6px 20px 0 rgba($color-black, 0.13);
        }
        // padding-bottom: 3rem;

        @include respond(tab-port) {
          max-width: 100%;
          width: 100%;
          margin-bottom: 3rem;
        }
      }
    }
  }
}

// partnership
.partnership {
  &__head {
    text-align: center;
    color: $color-grey-dark-2;
    font-size: 2rem;
    margin: 12rem 0 8rem;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10rem;

    @include respond(tab-port) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
    }

    &--textbox {
      font-size: $default-font-size;

      &-head {
        text-align: center;
        &-title {
          font-size: 3rem;
          font-weight: 800;
          text-transform: uppercase;
        }

        &-logo {
          width: 50%;
          margin: 2rem 0;

          @include respond(tab-port) {
            width: 100%;
          }
        }
      }

      &-text {
        color: $color-grey-dark-3;
        font-size: 1.7rem;
        line-height: 1.65;
        margin-bottom: 3rem;
        padding-left: 5rem;
        text-align: justify;

        @include respond(tab-port) {
          text-align: left;
          padding: 0 1rem;
        }
      }
    }

    &--img {
      width: 50%;
      max-width: 100%;
      height: auto;

      @include respond(tab-port) {
        width: 100%;
        margin-bottom: 3rem;
      }
    }
  }
}

// ====================================================
// Our quality section

.quality {
  font-family: "Inter", sans-serif;
  // min-height: 100vh;
  padding: 6rem 0 10rem 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;

  &__main {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include respond(tab-port) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &--textbox {
      width: 50%;

      @include respond(tab-port) {
        width: 100%;
        margin: 5rem;
      }
      &-text {
        font-size: 1.7rem;
        color: $color-grey-dark-2;
        line-height: 2;
        padding-bottom: 8rem;
        text-align: justify;

        @include respond(tab-port) {
          text-align: left;
        }
      }

      &-button {
        background-color: $color-blue;
        padding: 1.2rem 5rem;
        color: $color-white;
        border: none;
        border-radius: 100px;
        font-family: "Poppins", sans-serif;
        cursor: pointer;
        font-weight: 600;
        letter-spacing: 1px;
        font-size: 1.5rem;
        box-shadow: 0px 0px 20px rgba($color-black, 0.1);
        text-decoration: none;

        @include respond(tab-port) {
          display: flex;
          justify-content: center;
          margin: 0 3rem;
        }

        &:hover {
          background-color: $color-blue-dark;
          // box-shadow: 5px 5px 10px rgba($color-blue-dark, 0.2);
        }
      }
    }

    &--img {
      width: 35%;
      max-width: 100%;
      height: auto;
      transition: all 0.2s ease-in-out;

      @include respond(tab-port) {
        width: 100%;
      }

      &:hover {
        box-shadow: 0 4px 8px 0 rgba($color-black, 0.14),
          0 6px 20px 0 rgba($color-black, 0.13);
      }
    }
  }
}

// stats count
.stats {
  // max-width: 100% !important;
  padding: 5rem 0;
  margin: auto;
  @include gridblock(0, center);
  // gap: 0;

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5rem;
    text-align: center;
    border: 1px solid #ccc;

    &:not(:last-child) {
      border-right: 0px;

      @include respond(phone) {
        border-right: 1px solid #ccc;
      }
    }

    &--counter {
      font-size: 4rem;
      color: $color-green;

      &-orange {
        color: $color-orange;
      }

      &-red {
        color: $color-red;
      }

      &-blue {
        color: $color-blue-dark;
      }
    }

    &--title {
      font-size: 1.6rem;
      color: $color-grey-dark-2;
    }

    &--icon {
      font-size: 5rem !important;
      color: $color-green;
      margin-bottom: 2rem;

      &-orange {
        color: $color-orange;
      }

      &-red {
        color: $color-red;
      }

      &-blue {
        color: $color-blue-dark;
      }
    }
  }
}

// banner
.banner {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__textbox {
    &--subtitle {
      font-size: 1.4rem;
      color: $color-grey-dark;
      margin-bottom: 2rem;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 3px;

      @include respond(tab-port) {
        display: none;
      }
    }

    &--title {
      font-size: 3.5rem;
      color: $color-blue-dark;
      margin-bottom: 2rem;
      font-weight: 800;

      @include respond(tab-port) {
        padding: 10rem 0;
        text-align: center;
      }
    }
  }

  &__img {
    width: 50%;
    max-width: 100%;
    height: auto;
    pointer-events: none;
    user-select: none;

    @include respond(tab-port) {
      display: none;
    }
  }
}
