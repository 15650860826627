// mobile nav
.navbar {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1rem 2rem;
  // background: white;
  z-index: 1;
  font-size: 1.6rem;

  &--home {
    position: absolute;
  }

  &--white {
    background-color: white;
  }

  &--transparent {
    background-color: transparent;
  }
}

.nav-header {
  display: flex;
  justify-content: space-between;
}
.nav-btn {
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
}
.nav-icon {
  font-size: 2.3rem;
  color: $color-brown-dark;
}
.nav-links {
  height: 0;
  overflow: hidden;
  // transition: all 0.1s linear;
  list-style-type: none;
  vertical-align: center;
}
.nav-links a {
  display: block;
  text-decoration: none;
  padding: 1rem 0;
  color: $color-black;
  // transition: all 0.1s linear;
  text-align: center;
  font-size: 1.3rem;
  font-weight: 600;
}
.nav-links a:hover {
  color: $color-brown-dark;
  text-decoration: underline;
}

.show-nav {
  height: 250px;
}

// @media screen and (min-width: 768px) {
//   .nav-btn {
//     display: none;
//   }
//   .nav-center {
//     max-width: 1170px;
//     margin: 0 auto;
//     display: flex;
//   }
//   .nav-links {
//     height: auto;
//     display: flex;
//     margin-left: 4rem;
//   }
//   .nav-links a {
//     margin: 0 1rem;
//     padding: 0.5rem 0;
//   }
// }

// DESKTOP Version

.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  font-size: 1.4rem;
  padding: 1.8rem 0;

  &--active {
    background-color: $color-white;
  }

  @include respond(tab-land) {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  &__logo {
    font-size: 2.2rem;
  }

  &__sublogotext {
    font-size: 1.2rem;
    color: rgb(63, 63, 63);
    text-align: center;
    // font-style: italic;
  }

  &__nav {
    flex: 1;
    padding-left: 3rem;
    padding-right: 3rem;
  }

  &__list {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;

    &-item {
      margin-right: 3rem;
      transition: all 0.1s ease;

      &:hover {
        border-bottom: 2px solid $color-blue-dark;
      }
    }

    &-link {
      text-decoration: none;
      color: $color-grey-dark-4;
    }
  }

  &__cta {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  // navbar for pages
  // &__pages {

  // }

  &--white {
    background-color: white;
    padding-left: 5rem;
    padding-right: 5rem;
    // background-color: white;
    -webkit-backdrop-filter: blur(1px);
    backdrop-filter: blur(1px);
    position: sticky;
    top: 0;
    box-shadow: 0 1px 5px rgba($color-black, 0.13);
    z-index: 1000;
  }

  &--transparent {
    background-color: transparent;
  }
}
